import styles from './error500.module.scss';

export default function Error500() {

    return(
        <div className={styles.container}>
            <h1>Kunde inte hämta</h1>
            <p>Prova att ladda om sidan eller försök igen senare.</p>
        </div>
    )
}