import { useEffect, useState } from 'react';
import { useGetEntryById, useGetEntriesByContentType } from '../../utils/js/getStrapiContent';
import { cloneDeep } from 'lodash'
import HeadTags from '../../components/HeadTags/HeadTags';
import LoadingDots from '../../components/LoadingDots/LoadingDots';
import Error500 from '../Error500/Error500';
import HeroCarousel from "../../components/HeroCarousel/HeroCarousel";
import NewsletterListItem from '../../components/NewsletterListItem/NewsletterListItem';
import styles from './newsletters-page.module.scss';

export default function NewslettersPage({ setCurrentTheme }) {
    const { data, isPending, error } = useGetEntryById('solhemmet-newsletters-page')
    const { data: cards, error: cardsError } = useGetEntriesByContentType('solhemmet-newsletters')
    const [newsletters, setNewsletters] = useState();

    useEffect(() => {
        setCurrentTheme({
            propertyType: 'default',
            color: '#EEA550'
        })
    }, [setCurrentTheme])


    useEffect(() => {
        if (cards) {
            let temp = cloneDeep(cards.data);
            setNewsletters(temp.reverse())
        }
    }, [cards])


    return (
        <div className={styles.container}>
            {isPending && <LoadingDots />}
            {(error || cardsError) && <Error500 />}
            {(data && newsletters) && (
                <>
                    {data.data.attributes.seo && <HeadTags seo={data.data.attributes.seo} />}
                    {data.data.attributes.hero && <HeroCarousel slides={data.data.attributes.hero} hideSun={false} />}
                    {newsletters && <NewsletterListItem cards={newsletters} />}
                </>
            )}
        </div>

    )
}