import SlimCard from '../SlimCard/SlimCard';
import styles from './slim-cards.module.scss';

export default function SlimCards({ cards, columns }) {

    return (
        <div className={`${styles.section_container} 
            ${columns > 3 ? `${styles.over_3_columns}` : ''}
        `}
            style={{ gridTemplateColumns: `repeat(${columns}, 384px)` }}
        >

            {cards.map((card) =>
                <SlimCard card={card} key={card.id} />
            )}

        </div>
    )
}