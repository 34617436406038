import { Link } from 'react-router-dom';
import formatStrapiImage from '../../utils/js/formatStrapiImage';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import SolhemmetSun from '../SolhemmetSun/SolhemmetSun';
import styles from './slim-card.module.scss';

export default function SlimCard({ card }) {
    return (<>
        {card.link.toLowerCase().includes('https://')
            ?
            <a href={`${card.link}`} target='_blank' rel='noreferrer' className={`hover-img-zoom ${styles.card_container}`}>
                <Card card={card} />
            </a>
            :
            <Link to={`${card.link}`} className={`hover-img-zoom ${styles.card_container}`}>
                <Card card={card} />
            </Link>
        }
    </>
    )
}

function Card({ card }) {
    return (
        <div className={styles.card}>
            <div className={styles.sun_container}>
                <SolhemmetSun color={'#fff'} hide={false} small={true} />
                {card.tags.length > 0 &&
                    card.tags.map((tag) => (
                        <p key={tag.id}>{tag.tag}</p>
                    ))
                }
            </div>

            <div className={styles.img_container}>
                <img src={formatStrapiImage(card.image)} alt={card.image.data.attributes.alternativeText} />
            </div>

            <div className={styles.text_container}>
                <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={card.text} />
                <p className="p-arrow">Läs mer <span className="link-arrow">➞</span></p>
            </div>
        </div>
    )
}