import { Helmet } from "react-helmet-async"

export default function HeadTagsDefault({ title }) {
    return (
        <>
            <Helmet prioritizeSeoTags>
                {title ? <title>{title}</title> : <title>Solhemmet</title>}
                <meta name="description" content="Solhemmet Samhällsfastigheter AB är en specialiserad aktör som producerar, utvecklar, äger och hyr ut samhällsfastigheter" />

                <meta property="og:title" content={title ? title : "Solhemmet"} />
                <meta property="og:description" content="Solhemmet Samhällsfastigheter AB är en specialiserad aktör som producerar, utvecklar, äger och hyr ut samhällsfastigheter" />
                <meta property="og:image" content="%PUBLIC_URL%/Sun512.png" />
                <meta property="og:image:height" content="512" />
                <meta property="og:image:width" content="512" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title ? title : "Solhemmet"} />
                <meta name="twitter:description" content="Solhemmet Samhällsfastigheter AB är en specialiserad aktör som producerar, utvecklar, äger och hyr ut samhällsfastigheter" />
                <meta name="twitter:image" content="%PUBLIC_URL%/Sun512.png" />
                <meta name="twitter:image:alt" content="Solhemmet" />
            </Helmet>
        </>
    )
}