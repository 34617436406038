import formatStrapiImage from '../../utils/js/formatStrapiImage';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import SolhemmetSun from '../SolhemmetSun/SolhemmetSun';
import styles from './hero-slide.module.scss';

export default function HeroSlide({ slide, hideSun }) {

    return (
        <div className={styles.embla__slide}>
            {slide.text &&
                <div className={`${styles.slide__text__contstraints}`}>
                    {slide.secondaryText &&
                        <div className={styles.extra_text}>
                            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={slide.secondaryText} />
                        </div>
                    }

                    <SolhemmetSun color={'#fff'} hide={hideSun} />
                    <div className={styles.slide__text}>
                        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={slide.text} />
                    </div>
                </div>
            }

            <img src={formatStrapiImage(slide.image)} alt={slide.image.data.attributes.alternativeText} />
        </div>
    )
}