import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useGetEntryByContentTypeAndSlug } from '../../utils/js/getStrapiContent';
import getThemeSettings from '../../utils/js/getThemeSetting';
import HeadTags from '../../components/HeadTags/HeadTags';
import LoadingDots from '../../components/LoadingDots/LoadingDots';
import Error404 from '../Error404/Error404';
import HeroCarousel from '../../components/HeroCarousel/HeroCarousel'
import SlimCards from '../../components/SlimCards/SlimCards';
import ImageAndText from '../../components/ImageAndText/ImageAndText';
import TextSection from '../../components/TextSection/TextSection'
import styles from './project-template.module.scss'

export default function ProjectTemplate({ setCurrentTheme }) {
    const { identifier } = useParams();
    const { data, isPending, error } = useGetEntryByContentTypeAndSlug('solhemmet-project', identifier)


    useEffect(() => {
        if (data) {
            if (data.data.attributes.theme) {
                let tempTheme = getThemeSettings(data.data.attributes.theme.themes);
                setCurrentTheme(tempTheme)
            }
        }
    }, [data, setCurrentTheme])


    return (
        <div className={styles.container}>
            {isPending && <LoadingDots />}
            {error && <Error404 />}
            {data && (
                <>
                    {data.data.attributes.seo && <HeadTags seo={data.data.attributes.seo} />}
                    {data.data.attributes.hero && <HeroCarousel slides={data.data.attributes.hero} hideSun={false} />}
                    {data.data.attributes.cards.length > 0 && <SlimCards cards={data.data.attributes.cards} columns={3} />}
                    {data.data.attributes.imageAndText.length > 0 && <ImageAndText cards={data.data.attributes.imageAndText} />}
                    {data.data.attributes.text && <TextSection cards={data.data.attributes.text} />}
                </>
            )}
        </div>
    )
}