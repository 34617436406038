import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './cookie-consent.module.scss'

export default function CookieConsent() {
    const [hideCookieConsent, setHideCookieConsent] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookie-consent')
        if (consent) {
            setHideCookieConsent(JSON.parse(consent))
        } else {
            setHideCookieConsent(false)
        }
    }, [])


    function acceptAll() {
        localStorage.setItem('cookie-consent', 'true')
        localStorage.setItem('third-party-cookie-consent', 'true')
        setHideCookieConsent(true)
    }

    function acceptNecessary() {
        localStorage.setItem('cookie-consent', 'true')
        setHideCookieConsent(true)
    }

    return (
        <>
            {!hideCookieConsent ?
                <div className={`${styles.container} ${hideCookieConsent ? `${styles.hidden}` : ""}`} >
                    <p>Den här hemsidan använder cookies för att fungera och för att ge en bättre användarupplevelse.
                        <span> <Link to="/om-hemsidan">Läs mer/inställningar</Link></span>
                    </p>
                    <button onClick={acceptNecessary} className="btn btn-secondary">Bara nödvändiga</button>
                    <button onClick={acceptAll} className="btn">Acceptera alla</button>
                </div>
                : ""}
        </>
    )
}