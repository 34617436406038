import formatStrapiImage from '../../utils/js/formatStrapiImage';
import styles from './external-links-cards.module.scss';

export default function ExternalLinksCards({ cards, columns }) {
    return (
        <div className={styles.section_container} style={{ gridTemplateColumns: `repeat(${columns}, 228px)` }}>
            {cards.map((card) =>
                <a href={card.link} target="_blank" rel="noreferrer" key={card.id}>
                    <div className={styles.card_container}>

                        <div className={styles.img_container}>
                            <img src={formatStrapiImage(card.image)} alt={card.image.data.attributes.alternativeText} />
                        </div>

                    </div>
                </a>
            )}
        </div>
    )
}