import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useGetEntryByContentTypeAndSlug } from '../../utils/js/getStrapiContent';
import HeadTags from '../../components/HeadTags/HeadTags';
import LoadingDots from '../../components/LoadingDots/LoadingDots';
import Error404 from '../Error404/Error404';
import HeroCarousel from '../../components/HeroCarousel/HeroCarousel';
import SlimCards from '../../components/SlimCards/SlimCards';
import styles from './municipality-template.module.scss';

export default function MunicipalityTemplate({ setCurrentTheme }) {
    const { identifier } = useParams();
    const { data, isPending, error } = useGetEntryByContentTypeAndSlug('solhemmet-municipality', identifier)


    useEffect(() => {
        setCurrentTheme({
            propertyType: 'default',
            color: '#EEA550'
        })
    }, [setCurrentTheme])


    return (
        <div className={styles.container}>
            {isPending && <LoadingDots />}
            {error && <Error404 />}
            {data && (
                <>
                    {data.data.attributes.seo && <HeadTags seo={data.data.attributes.seo} />}
                    {data.data.attributes.hero && <HeroCarousel slides={data.data.attributes.hero} hideSun={false} />}
                    {data.data.attributes.cards.length > 0 && <SlimCards cards={data.data.attributes.cards} columns={2} />}
                </>
            )}
        </div>
    )
}