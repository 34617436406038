import { useEffect } from 'react';
import { useGetEntryById } from '../../utils/js/getStrapiContent';
import HeadTags from '../../components/HeadTags/HeadTags';
import LoadingDots from '../../components/LoadingDots/LoadingDots';
import Error500 from '../Error500/Error500';
import HeroCarousel from "../../components/HeroCarousel/HeroCarousel";
import ExternalLinksCards from '../../components/ExternalLinksCards/ExternalLinksCards';
import styles from './partners-page.module.scss';

export default function PartnersPage({ setCurrentTheme }) {
    const { data, isPending, error } = useGetEntryById('solhemmet-partners-page')


    useEffect(() => {
        setCurrentTheme({
            propertyType: 'default',
            color: '#EEA550'
        })
    }, [setCurrentTheme])


    return (
        <div className={styles.container}>
            {isPending && <LoadingDots />}
            {error && <Error500 />}
            {data && (
                <>
                    {data.data.attributes.seo && <HeadTags seo={data.data.attributes.seo} />}
                    {data.data.attributes.hero && <HeroCarousel slides={data.data.attributes.hero} hideSun={false} />}
                    {data.data.attributes.cards && <ExternalLinksCards cards={data.data.attributes.cards} columns={4} />}
                </>
            )}
        </div>
    )
}