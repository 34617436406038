import formatStrapiImage from '../../utils/js/formatStrapiImage';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from './newsletter-opening.module.scss';

export default function NewsletterOpening({ cards }) {

    return (<>
        {cards.map((card) => (
            <div className={styles.container} key={card.id}>

                {card.image &&
                    <div className={styles.img_container}>
                        <img src={formatStrapiImage(card.image)} alt={card.image.data.attributes.alternativeText} />
                    </div>
                }

                <div className={styles.text_container}>
                    <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={card.text} />
                </div>
            </div>
        ))}
    </>)
}