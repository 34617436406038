import formatStrapiImage from '../../utils/js/formatStrapiImage';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from './newsletter-byline.module.scss';

export default function NewsletterByline({ image, signature, text }) {

    return (
        <div className={styles.container}>
            <div className={styles.signature_container}>
                <h3>Allt gott</h3>
                <div className={styles.signature_img_container}>
                    <img src={formatStrapiImage(signature.data)} alt={signature.data.attributes.alternativeText} />
                </div>
                <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={text} />
            </div>

            <div className={styles.img_container}>
                <img src={formatStrapiImage(image.data)} alt={image.data.attributes.alternativeText} />
            </div>
        </div>
    )
}