import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"

export default function HeadTags({ seo }) {
    const [ogTags, setOgTags] = useState({});
    const [twitterTags, setTwitterTags] = useState({});


    useEffect(() => {
        if (seo) {
            setFromStandardMetaTags(seo)

            if (seo.metaSocial.length > 0) {
                seo.metaSocial.forEach((ms) => {
                    if (ms.socialNetwork === 'Facebook') {
                        let tempOg = {
                            title: ms.title ? ms.title : (seo.metaTitle ? seo.metaTitle : ''),
                            description: ms.description ? ms.description : (seo.metaDescription ? seo.metaDescription : ''),
                            imageUrl: ms.image.data ? ms.image.data.attributes.url : (seo.metaImage ? seo.metaImage.data.attributes.url : ''),
                            imageAlternativeText: ms.image.data ? ms.image.data.attributes.alternativeText : (seo.metaImage ? seo.metaImage.data.attributes.alternativeText : ''),
                            height: ms.image.data ? ms.image.data.attributes.height : (seo.metaImage ? seo.metaImage.data.attributes.height : ''),
                            width: ms.image.data ? ms.image.data.attributes.width : (seo.metaImage ? seo.metaImage.data.attributes.width : ''),
                        }
                        setOgTags(tempOg);
                    }

                    if (ms.socialNetwork === 'Twitter') {
                        let tempTwitter = {
                            title: ms.title ? ms.title : (seo.metaTitle ? seo.metaTitle : ''),
                            description: ms.description ? ms.description : (seo.metaDescription ? seo.metaDescription : ''),
                            imageUrl: ms.image.data ? ms.image.data.attributes.url : (seo.metaImage ? seo.metaImage.data.attributes.url : ''),
                            imageAlternativeText: ms.image.data ? ms.image.data.attributes.alternativeText : (seo.metaImage ? seo.metaImage.data.attributes.alternativeText : ''),
                        }
                        setTwitterTags(tempTwitter);
                    }
                })
            }
        }
    }, [seo])


    function setFromStandardMetaTags(seo) {
        let tempOg = {
            title: seo.metaTitle ? seo.metaTitle : '',
            description: seo.metaDescription ? seo.metaDescription : '',
            imageUrl: seo.metaImage ? seo.metaImage.data.attributes.url : '',
            imageAlternativeText: seo.metaImage ? seo.metaImage.data.attributes.alternativeText : '',
            height: seo.metaImage ? seo.metaImage.data.attributes.height : '',
            width: seo.metaImage ? seo.metaImage.data.attributes.width : '',
        }
        setOgTags(tempOg);

        let tempTwitter = {
            title: seo.metaTitle ? seo.metaTitle : '',
            description: seo.metaDescription ? seo.metaDescription : '',
            imageUrl: seo.metaImage ? seo.metaImage.data.attributes.url : '',
            imageAlternativeText: seo.metaImage ? seo.metaImage.data.attributes.alternativeText : '',
        }
        setTwitterTags(tempTwitter);
    }

    return (
        <Helmet prioritizeSeoTags>
            <title>{seo.metaTitle}</title>
            <meta name="description" content={seo.metaDescription} />

            {ogTags.title && <meta property="og:title" content={ogTags.title} />}
            {ogTags.description && <meta property="og:description" content={ogTags.description} />}
            {ogTags.imageUrl && <meta property="og:image" content={ogTags.imageUrl} />}
            {ogTags.imageAlternativeText && <meta property="og:image:alt" content={ogTags.imageAlternativeText} />}
            {ogTags.height && <meta property="og:image:height" content={ogTags.height} />}
            {ogTags.width && <meta property="og:image:width" content={ogTags.width} />}

            {twitterTags.title && <meta name="twitter:card" content="summary_large_image" />}
            {twitterTags.title && <meta name="twitter:title" content={twitterTags.title} />}
            {twitterTags.description && <meta name="twitter:description" content={twitterTags.description} />}
            {twitterTags.imageUrl && <meta name="twitter:image" content={twitterTags.imageUrl} />}
            {twitterTags.imageAlternativeText && <meta name="twitter:image:alt" content={twitterTags.imageAlternativeText} />}
        </Helmet>
    )
}