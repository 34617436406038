export default function getThemeSettings(tag) {
    let settings = {
        propertyType: 'default',
        color: '#EEA550'
    };


    if (tag.toLowerCase() === 'seniorboende') {
        settings = {
            propertyType: 'seniorboende',
            color: '#EEA550'
        }
    }

    if (tag.toLowerCase() === 'förskola') {
        settings = {
            propertyType: 'förskola',
            color: '#F1BDB2'
        }
    }

    if (tag.toLowerCase() === 'skola') {
        settings = {
            propertyType: 'skola',
            color: '#556D62'
        }
    }

    if (tag.toLowerCase() === 'särskilt boende för äldre') {
        settings = {
            propertyType: 'särskilt boende för äldre',
            color: '#636267'
        }
    }

    if (tag.toLowerCase() === 'entreprenörslägenhet') {
        settings = {
            propertyType: 'entreprenörslägenhet',
            color: '#D9C4A9'
        }
    }

    if (tag.toLowerCase() === 'trygghetsboende') {
        settings = {
            propertyType: 'trygghetsboende',
            color: '#C7C8CA'
        }
    }

    if (tag.toLowerCase() === 'default') {
        settings = {
            propertyType: 'default',
            color: '#EEA550'
        }
    }

    return settings
}