import styles from './gallery-selected-item.module.scss';

export default function GallerySelectedItem({ item, toggleItem }) {

    return (
        <div className={styles.container}>
            <div className={styles.background} onClick={() => toggleItem()} title='Stäng' />
            <div className={styles.img_container}>
                <img src={item.url} alt={item.alt} />
            </div>
        </div>
    )
}