import React, { useEffect } from 'react';
import { useGetEntryById, useGetEntriesByContentType } from '../../utils/js/getStrapiContent';
import LoadingDots from '../../components/LoadingDots/LoadingDots';
import Error500 from '../Error500/Error500';
import HeadTags from '../../components/HeadTags/HeadTags';
import HeroCarousel from "../../components/HeroCarousel/HeroCarousel";
import WideCards from '../../components/WideCards/WideCards';
import styles from './frontpage.module.scss';

export default function FrontPage({ setCurrentTheme }) {
    const { data, isPending, error } = useGetEntryById('solhemmet-frontpage')
    const { data: cards, error: cardsError } = useGetEntriesByContentType('solhemmet-property-types')


    useEffect(() => {
        setCurrentTheme({
            propertyType: 'default',
            color: '#EEA550'
        })
    }, [setCurrentTheme])


    return (
        <div className={styles.container}>
            {isPending && <LoadingDots />}
            {(error || cardsError) && <Error500 />}
            {(data && cards) && (
                <>
                    {data.data.attributes.seo && <HeadTags seo={data.data.attributes.seo} />}
                    {data.data.attributes.hero && <HeroCarousel slides={data.data.attributes.hero} hideSun={false} />}
                    {cards.data && <WideCards cards={cards.data} />}
                </>
            )}
        </div>
    )
}