import { Link } from 'react-router-dom';
import formatStrapiImage from '../../utils/js/formatStrapiImage';
import SolhemmetSun from '../SolhemmetSun/SolhemmetSun';
import styles from './wide-cards.module.scss';

export default function WideCards({ cards }) {

    return (
        <div className={styles.section_container}>
            {cards.map((card) =>
                <Link to={`/fastigheter/${card.attributes.slug}`} key={card.id} className="hover-img-zoom">
                    <div className={styles.card_container}>

                        <div className={styles.img_container}>
                            <img src={formatStrapiImage(card.attributes.hero[0].image)} alt={card.attributes.hero[0].image.data.attributes.alternativeText} />
                        </div>

                        <div className={styles.text_container}>
                            <div className={styles.sun_container}>
                                <SolhemmetSun color={'#fff'} hide={false} small={true} />
                            </div>

                            <h3>{card.attributes.title}</h3>
                            <p className="p-arrow">Läs mer <span className="link-arrow">➞</span></p>
                        </div>

                    </div>
                </Link>
            )}
        </div>
    )
}