import { useState, useEffect } from 'react';
import { useGetEntryByContentTypeAndSlug } from '../../utils/js/getStrapiContent';
import LoadingDots from '../../components/LoadingDots/LoadingDots';
import Error404 from '../Error404/Error404';
import HeadTags from '../../components/HeadTags/HeadTags';
import HeroCarousel from '../../components/HeroCarousel/HeroCarousel';
import TextSection from '../../components/TextSection/TextSection';
import styles from './cookie-preferences.module.scss';

export default function CookiePreferences({ setCurrentTheme }) {
    const { data, isPending, error } = useGetEntryByContentTypeAndSlug('solhemmet-information', 'om-hemsidan-solhemmet')
    const [thirdPartyCookiesAccepted, setThirdPartyCookiesAccepted] = useState();


    useEffect(() => {
        setCurrentTheme({
            propertyType: 'default',
            color: '#EEA550'
        })
    }, [setCurrentTheme])


    useEffect(() => {
        const consent = localStorage.getItem('third-party-cookie-consent')
        if (consent) {
            setThirdPartyCookiesAccepted(JSON.parse(consent))
        } else {
            setThirdPartyCookiesAccepted(false)
        }
    }, [])


    function handleChange(e) {
        let checked = e.target.checked;
        localStorage.setItem('third-party-cookie-consent', checked);
        setThirdPartyCookiesAccepted(checked);
        localStorage.setItem('cookie-consent', 'true');
    }


    return (
        <div className={styles.container}>
            {isPending && <LoadingDots />}
            {error && <Error404 />}
            {data && (
                <>
                    {data.data.attributes.seo && <HeadTags seo={data.data.attributes.seo} />}
                    {data.data.attributes.hero && <HeroCarousel slides={data.data.attributes.hero} hideSun={false} />}
                    {data.data.attributes.text && <TextSection cards={data.data.attributes.text} />}

                    <div className={styles.preferences_container}>
                        <h4>Cookie-inställningar</h4>
                        <form>
                            <input
                                type="checkbox"
                                id="cookies"
                                checked={thirdPartyCookiesAccepted}
                                onChange={handleChange} />

                            <label htmlFor="cookies">Godkänn tredjeparts-cookies</label>
                        </form>
                    </div>
                </>
            )}
        </div>
    )
}