import styles from './solhemmet-sun.module.scss';

export default function SolhemmetSun({ color, hide, small }) {
    return (
        <div className={`${styles.container} ${hide ? styles.hide : ''}`}>
            <svg width="46" height="50" viewBox="0 0 46 50" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${small ? styles.small : ''}`}>
                <g clipPath="url(#clip0_1057_4766)">
                    <path d="M22.6237 0.0222983L18.9468 3.69922L22.6237 7.37614L26.3006 3.69922L22.6237 0.0222983Z" fill={color ? color : "#EEA550"} />
                    <path d="M45.3 23.9998L30.7 23.1998L40.3 12.2998L36.8 8.7998L25.8 18.5998L25 8.8998H20.1L19.3 18.6998L8.3 8.8998L4.8 12.3998L14.4 23.1998L0 23.9998V28.9998L14.5 29.7998L4.8 40.7998L8.3 44.2998L19.2 34.5998L20.1 49.1998H25L25.9 34.6998L36.8 44.3998L40.3 40.8998L30.5 29.8998L45.3 28.9998V23.9998Z" fill={color ? color : "#FAD7A5"} />
                </g>
                <defs>
                    <clipPath id="clip0_1057_4766">
                        <rect width="45.3" height="49.2" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}