import { useEffect } from 'react';
import getThemeSettings from '../../../utils/js/getThemeSetting';
import HeadTagsDefault from '../../../components/HeadTagsDefault/HeadTagsDefault';
import YoutubeEmbedSection from '../../../components/YoutubeEmbedSection/YoutubeEmbedSection';
import styles from './renfallen-survey.module.scss';

export default function RenfallenSurvey({ setCurrentTheme }) {

    useEffect(() => {
        let tempTheme = getThemeSettings('seniorboende');
        setCurrentTheme(tempTheme)
    }, [setCurrentTheme])



    return (
        <>
            <HeadTagsDefault title={'Renfällen Enkätsvar - Solhemmet'} />



            <div className={styles.container}>

                <section className={styles.section_1}>
                    <div className={styles.constraints}>
                        <h1>HUR SKULLE DU VILJA UTFORMA DITT HEM PÅ RENFÄLLEN?</h1>
                        <p>Frågeformulär för att undersöka hur Kirunabor skulle vilja utforma sina hem på Renfällen.</p>
                        <p>Resultaten är helt anonyma och används endast som en del i beslutsunderlaget för utformningen av boendet.</p>
                    </div>
                </section>



                <section className={styles.section_2}>

                    <div className={styles.constraints}>

                        <div className={styles.section_2_top}>
                            <div className={styles.section_2_top_left}>
                                <h2>VAD TYCKER DU ÄR BÄST?</h2>

                                <img src='images/survey/washing.jpg' alt='Resultat enkät' />
                            </div>

                            <div className={styles.section_2_top_right}>
                                <img src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/large_annie_spratt_Ue_ZA_Fxp5_Eh_I_unsplash_rqta9e_51326d2475.jpg' alt='Tvätt' />
                            </div>
                        </div>
                    </div>
                    <div className={styles.section_2_bot}>
                        <p>"RYMLIGT KÖK ÖVER ETT RYMLIGT BADRUM"</p>
                    </div>
                </section>



                <section className={styles.section_3}>
                    <img className={styles.section_3_bg} src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/large_3840x2160_ikujvv_af31dfd92d.jpg' alt='Rendering utsidan' />

                    <div className={styles.section_3_bot}>
                        <h2>55% VILL HA ETT KAMERASYSTEM MED TELEFON VID DÖRREN</h2>

                        <img src='images/survey/pie_chart.png' alt='Resultat enkät' />
                    </div>
                </section>



                <section className={styles.section_4}>
                    <div className={styles.constraints}>
                        <div className={styles.section_4_left}>
                            <h2>VAD <br /> ÄGER<br /> DU?</h2>
                        </div>

                        <img src='images/survey/things-you-own.jpg' alt='Resultat enkät' />
                    </div>
                </section>



                <section className={styles.section_5}>
                    <div className={styles.section_5_bot}>
                        <div className={styles.section_5_yt}>
                            <YoutubeEmbedSection cards={
                                [
                                    {
                                        Id: 1,
                                        videoId: 'GNpN4G0a0mo'
                                    }
                                ]
                            }
                            />
                        </div>

                        <h2 className={styles.section_5_h2}>SIMON FÖRKLARAR <br /> VAD ÄR DESIGN?</h2>
                    </div>
                </section>



                <section className={styles.section_6}>
                    <div className={styles.constraints}>
                        <div className={styles.section_6_left}>
                            <img src='images/survey/important-with-age.jpg' alt='Resultat enkät' />
                        </div>

                        <div className={styles.section_6_right}>
                            <h2>VAD ÄR VIKTIGT FÖR JU DIG ÄLDRE DU BLIR?</h2>

                            <div className={styles.section_6_right_img}>
                                <img src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/large_anthony_metcalfe_Q_Qs_RTGA_Zp9o_unsplash_folrbg_cropped_suwlja_5f0d2a0fdf.jpg' alt='Äldre kvinna' />
                            </div>

                        </div>
                    </div>
                </section>



                <section className={styles.section_7}>
                    <div className={styles.constraints}>

                        <div className={styles.section_7_left}>
                            <img src='images/survey/current.png' alt='Resultat enkät' />
                            <h2>HUR SKULLE DU BESKRIVA DITT NUVARANDE HEM?</h2>
                        </div>

                        <div className={styles.section_7_mid}>
                            <img src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/thumbnail_image001_fciuh0_187c7005a0.jpg' alt='Äldre man' />
                        </div>

                        <div className={styles.section_7_right}>
                            <h2>VAD SKULLE DU BESKRIVA SOM VIKTIGT I DITT NYA HEM?</h2>
                            <img src='images/survey/important-new-home.png' alt='Resultat enkät' />
                        </div>

                    </div>
                </section>



                <section className={styles.section_8}>
                    <div className={styles.constraints}>
                        <img className={styles.section_8_bg} src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/large_i_Stock_1212931050_ff94bs_1_2023fbb96c.jpg' alt='Äldre par på promenad' />

                        <div className={styles.section_8_content}>
                            <img src='images/survey/wifi.jpg' alt='Resultat enkät' />
                            <h2>91% SÄGER ATT ETT TRÅDLÖST NÄTVERK ÄR VIKTIGT</h2>
                        </div>
                    </div>
                </section>



                <section className={styles.section_9}>
                    <h2>OM DU JOBBAR HEMIFRÅN, VILKEN PLATS SKULLE DU VILJA ANVÄNDA?</h2>

                    <div className={styles.section_9_cards}>
                        <div className={styles.circle_card}>
                            <img src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/kitchen_2_twb94i_fd9cdf3798.jpg' alt='Kök' />
                            <p>VID KÖKSBORDET</p>
                            <p>21%</p>
                        </div>

                        <div className={styles.circle_card}>
                            <img src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/living_5_u4kgbb_7d305d80d0.jpg' alt='Vardagsrum' />
                            <p>EN PLATS I VARDAGSRUMMET</p>
                            <p>15%</p>
                        </div>

                        <div className={styles.circle_card}>
                            <img src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/office_1_wdgusm_52882a64ac.jpg' alt='Kontor' />
                            <p>ETT EGET KONTOR</p>
                            <p>63%</p>
                        </div>
                    </div>
                </section>



                <section className={styles.section_10}>
                    <h2>RENFÄLLEN <br /> ALLT DU BEHÖVER VID DIN DÖRR</h2>

                    <div className={styles.section_10_cards}>
                        <div className={styles.circle_card}>
                            <img src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/large_i_Stock_861191192_j1h9kd_d6932a064f.jpg' alt='Boule' />
                            <p>AKTIVITETER</p>
                        </div>

                        <div className={styles.circle_card}>
                            <img src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/large_i_Stock_1203315262_iimu29_5d01ff59cd.jpg' alt='Grupp vid ett bord utomhus' />
                            <p>GEMENSKAP</p>
                        </div>

                        <div className={styles.circle_card}>
                            <img src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/large_i_Stock_809822820_omomrf_18bd5c4ef8.jpg' alt='Sköterska' />
                            <p>OMTANKE</p>
                        </div>

                        <div className={styles.circle_card}>
                            <img src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/large_sebastian_pichler_sblp4evk2gs_unsplash_dtbp46_5b9f5df3e5.jpg' alt='Promenad i skogen' />
                            <p>NATUREN</p>
                        </div>
                    </div>
                </section>



                <section className={styles.section_11}>
                    <div className={styles.constraints}>
                        <h2>VILKA AV FÖLJANDE TJÄNSTER SKULLE DU HA NYTTA AV OM DE ERBJÖDS DIG?</h2>

                        <img src='images/survey/potential.jpg' alt='Resultat enkät' />
                    </div>
                </section>



                <section className={styles.section_12}>
                    <div className={styles.constraints}>
                        <img className={styles.section_12_bg} src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/large_i_Stock_861191192_j1h9kd_d6932a064f.jpg' alt='Boule' />

                        <div className={styles.section_12_content}>

                            <div> <div className={styles.s12_circle_1}>PICKNICKBORD <br /> 33%</div> </div>
                            <div> <div className={`${styles.s12_circle_2} ${styles.big_circle}`}>PARKBÄNKAR &<br /> SOFFOR <br /> 89%</div> </div>
                            <div> <div className={styles.s12_circle_3}>ODLA <br /> GRÖNSAKER <br /> 33%</div> </div>
                            <div> <div className={`${styles.s12_circle_4} ${styles.big_circle}`}>BASTU <br /> 62%</div> </div>
                            <div> <div className={`${styles.s12_circle_5} ${styles.big_circle}`}>SAMLINGS- & <br /> FESTLOKAL <br /> 62%</div> </div>
                            <div> <div className={styles.s12_circle_6}>UTOMHUSGYM <br /> 36%</div> </div>
                            <div> <div className={`${styles.s12_circle_7} ${styles.big_circle}`}>BUBBELBAD <br /> 15%</div> </div>
                            <div> <div className={styles.s12_circle_8}>SOLTERAPI-RUM <br /> 10%</div> </div>
                            <div> <div className={styles.s12_circle_9}>BOULE <br /> 24%</div> </div>

                        </div>

                        <h2>VINTERTRÄDGÅRDEN <br /> AKTIVITETSHUSET</h2>
                    </div>
                </section>



                <section className={styles.section_13}>
                    <h2>VAD ÄR VIKTIGAST?</h2>

                    <div className={styles.section_13_cards}>
                        <div className={styles.circle_card}>
                            <img src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/large_kitchen_2_twb94i_fd9cdf3798.jpg' alt='Kök' />
                            <p>RYMLIGT KÖK</p>
                            <p>90%</p>
                        </div>

                        <div className={styles.circle_card}>
                            <img src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/large_Living_1_wwlvfx_2949ed5c4f.jpg' alt='Vardagsrum' />
                            <p>RYMLIGT VARDAGSRUM</p>
                            <p>66%</p>
                        </div>

                        <div className={styles.circle_card}>
                            <img src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/large_bed1_yvoafe_465c2492c2.jpg' alt='Sovrum' />
                            <p>RYMLIGT SOVRUM</p>
                            <p>21%</p>
                        </div>

                        <div className={styles.circle_card}>
                            <img src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/FREEZER_s1idtq_da302f15f1.jpg' alt='Frys' />
                            <p>EXTRA FRYS</p>
                            <p>21%</p>
                        </div>

                        <div className={styles.circle_card}>
                            <img src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/BASTU_fqocb6_e512b345a5.jpg' alt='Bastu' />
                            <p>EGEN BASTU</p>
                            <p>20%</p>
                        </div>
                    </div>
                </section>



                <section className={styles.section_14}>
                    <div className={styles.constraints}>

                        <div className={styles.section_14_img}>
                            <img src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/michael_gshw5l_4ad39b5c9b.png' alt='Michael Guldstrand' />
                        </div>

                        <div className={styles.section_14_text}>
                            <p>VI ÄR SÅ GLADA ATT NI KUNDE DELTA I VÅR UNDERSÖKNING. DET KOMMER SÄKERT ATT BIDRA TILL ATT RENFÄLLEN BLIR ETT AV SVERIGES BÄSTA SENIORBOENDEN.</p>
                            <div className={styles.section_14_signed}>
                                <p>MICHAEL GULDSTRAND</p>
                                <p>SOLHEMMET</p>
                            </div>
                        </div>

                    </div>
                </section>



                <section className={styles.section_15}>
                    <div className={styles.constraints}>
                        <img className={styles.section_15_bg} src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/large_i_Stock_1131559482_jqsjuv_1_0c648d3366.jpg' alt='Middag' />

                        <div className={styles.section_15_content}>
                            <img src='images/survey/friends.jpg' alt='Resultat enkät' />

                            <h2>HUR BRUKAR DU UMGÅS MED DINA VÄNNER?</h2>
                        </div>
                    </div>
                </section>



                <section className={styles.section_16}>
                    <div className={styles.section_16_left}>
                        <img src='images/survey/interior.jpg' alt='Resultat enkät' />

                        <h2>VILKA INREDNINGS-DETALJER SKULLE DU VILJA HA I DITT NYA HEM?</h2>
                    </div>

                    <div className={styles.section_16_right}>
                        <img src='https://solhemmetcmsstorage.blob.core.windows.net/strapi-uploads/assets/large_dining_1_n9vyhp_71198f8ee0.jpg' alt='Vardagsrum' />
                    </div>

                </section>

            </div>
        </>
    )
}