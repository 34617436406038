import { useState } from 'react';
import formatStrapiImage from '../../utils/js/formatStrapiImage';
import GallerySelectedItem from '../GallerySelectedItem/GallerySelectedItem';
import styles from './gallery-cards.module.scss';

export default function GalleryCards({ cards, columns }) {
    const [selectedItem, setSelectedItem] = useState();
    const [showItem, setShowItem] = useState(false);

    function toggleItem(item) {
        if (item) {
            setSelectedItem({
                'url': item.attributes.url,
                'alt': item.attributes.alternativeText
            });
        }

        setShowItem(!showItem);

        if (showItem) {
            document.body.style.overflow = 'unset';
        } else {
            document.body.style.overflow = 'hidden';
        }
    }

    return (
        <>
            <div className={styles.section_container} style={{ gridTemplateColumns: `repeat(${columns}, 368px)` }}>
                {cards.map((card) =>
                    <div className={`hover-img-zoom ${styles.card_container}`} key={card.id} title={card.attributes.alternativeText} onClick={() => toggleItem(card)}>
                        <img src={formatStrapiImage(card)} alt={card.attributes.alternativeText} />
                    </div>
                )}
            </div>

            {showItem && <GallerySelectedItem item={selectedItem} toggleItem={toggleItem} />}
        </>
    )
}