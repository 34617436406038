import { Link } from 'react-router-dom';
import { useGetEntryById } from '../../utils/js/getStrapiContent';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from './footer.module.scss'

export default function Footer() {
    const { data } = useGetEntryById('solhemmet-footer')

    return (
        <footer className={styles.footer}>
            <div className={styles.constraints}>
                <Link to="/" className={styles.logo}>
                    <img src="/images/logo/current/Solhemmet_neg.svg" alt="Solhemmet logo" />
                </Link>

                <div className={styles.text_container}>
                    {data && <>
                        <div className={styles.text}>
                            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={data.data.attributes.text} />
                        </div>

                        {data.data.attributes.links &&
                            <div className={styles.links}>
                                {data.data.attributes.links.map((link) => (
                                    <Link to={link.link} key={link.id}>{link.title}</Link>
                                ))}
                            </div>
                        }
                    </>}
                </div>
            </div>
        </footer>
    )
}