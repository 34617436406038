import formatStrapiImage from '../../utils/js/formatStrapiImage';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from './image-and-text.module.scss';

export default function ImageAndText({ cards }) {

    return (
        <div className={styles.section_container}>
            {cards.map((card) =>
                <div className={styles.card_container} key={card.id}>
                    <div className={styles.content}>
                        {card.image.data &&
                            <div className={styles.img_container}>
                                <img src={formatStrapiImage(card.image)} alt={card.image.data.attributes.alternativeText} />
                            </div>
                        }

                        <div className={styles.text_container}>
                            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={card.text} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}