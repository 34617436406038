import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import CookieConsent from './components/CookieConsent/CookieConsent';
import Error404 from './pages/Error404/Error404'
import FrontPage from './pages/FrontPage/FrontPage';
import TypeTemplate from './pages/TypeTemplate/TypeTemplate';
import MunicipalityTemplate from './pages/MunicipalityTemplate/MunicipalityTemplate';
import ProjectTemplate from './pages/ProjectTemplate/ProjectTemplate';
import FloorplansTemplate from './pages/FloorplansTemplate/FloorplansTemplate';
import GalleryTemplate from './pages/GalleryTemplate/GalleryTemplate';
import TextContentTemplate from './pages/TextContentTemplate/TextContentTemplate';
// import ContactPage from './pages/ContactPage/ContactPage';
import NewslettersPage from './pages/NewslettersPage/NewslettersPage';
import NewsletterTemplate from './pages/NewsletterTemplate/NewsletterTemplate';
import PartnersPage from './pages/PartnersPage/PartnersPage';
import CookiePreferences from './pages/CookiePreferences/CookiePreferences';
import RenfallenSurvey from './pages/Surveys/RenfallenSurvey/RenfallenSurvey';
import './App.scss';


export default function App() {
  const [currentTheme, setCurrentTheme] = useState(null);

  return (
    <div className="App">

      <a href="#main-content" className="skip-link">Hoppa till huvudinnehåll</a>

      <Header currentTheme={currentTheme} />

      <div className="content" id="main-content">
        <Routes>

          <Route path='/' element={<FrontPage setCurrentTheme={setCurrentTheme} />} />

          <Route path='/fastigheter/:identifier' element={<TypeTemplate setCurrentTheme={setCurrentTheme} />} />

          <Route path='/kommun/:identifier' element={<MunicipalityTemplate setCurrentTheme={setCurrentTheme} />} />

          <Route path='/projekt/:identifier' element={<ProjectTemplate setCurrentTheme={setCurrentTheme} />} />

          <Route path='/planritningar/:identifier' element={<FloorplansTemplate setCurrentTheme={setCurrentTheme} />} />

          <Route path='/galleri/:identifier' element={<GalleryTemplate setCurrentTheme={setCurrentTheme} />} />

          <Route path='/info/:identifier' element={<TextContentTemplate setCurrentTheme={setCurrentTheme} />} />

          {/* <Route path='/kontakt' element={<ContactPage setCurrentTheme={setCurrentTheme} />} /> */}

          <Route path='/nyhetsbrev' element={<NewslettersPage setCurrentTheme={setCurrentTheme} />} />

          <Route path='/nb/:identifier' element={<NewsletterTemplate setCurrentTheme={setCurrentTheme} />} />

          <Route path='/partners' element={<PartnersPage setCurrentTheme={setCurrentTheme} />} />

          <Route path='/om-hemsidan' element={<CookiePreferences setCurrentTheme={setCurrentTheme} />} />

          <Route path='/renfallen-enkatsvar' element={<RenfallenSurvey setCurrentTheme={setCurrentTheme} />} />

          <Route path='*' element={<Error404 />} />

        </Routes>
      </div>

      <CookieConsent />
      <Footer />

    </div>
  );
}
