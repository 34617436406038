import styles from './error404.module.scss';

export default function Error404() {

    return(
        <div className={styles.container}>
            <h1>404</h1>
            <p>Sidan kunde inte hittas.</p>
        </div>
    )
}