import { useState, useEffect } from 'react';
import { useGetEntryById } from '../../utils/js/getStrapiContent';
import { Link } from 'react-router-dom';
import { throttle } from 'lodash'
import styles from './header.module.scss';

export default function Header({ currentTheme }) {
    const { data } = useGetEntryById('solhemmet-header')
    const [showNav, setNav] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [accentColor, setAccentColor] = useState();
    const [activeDropdown, setActiveDropdown] = useState(null);


    useEffect(() => {
        if (currentTheme) {
            setAccentColor(currentTheme.color);
        }
    }, [currentTheme]);


    useEffect(() => {
        const throttledScroll = throttle(() => {
            if (window.pageYOffset >= 160) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        }, 100);
        window.addEventListener('scroll', throttledScroll);
        return () => window.removeEventListener('scroll', throttledScroll);
    }, []);


    function toggleNav() {
        setNav(!showNav)

        if (showNav) {
            document.body.style.overflow = 'unset';
        } else {
            document.body.style.overflow = 'hidden';
        }
    }


    function toggleDropdown(id) {
        if (id !== activeDropdown) {
            setActiveDropdown(id);
        } else {
            setActiveDropdown(null);
        }
    }


    return (
        <>
            <header className={`${styles.header} ${scrolled ? `${styles.scrolled}` : ""}`} style={{ borderColor: accentColor }}>
                <div className={styles.constraints}>
                    <div className={styles.logo_container}>
                        <Link aria-label='Links to front page' to="/" onClick={() => toggleDropdown(null)}>
                            <img src="/images/logo/current/Solhemmet_logo.svg" alt="Solhemmet logo" />
                        </Link>
                    </div>

                    {data &&
                        <nav className={styles.nav_dropdown_container} aria-label='Main navigation'>
                            {data.data.attributes.linkGroups.length > 0 && data.data.attributes.linkGroups.map((card) => (
                                <div key={card.id} className={`${styles.dropdown_menu} ${card.id === activeDropdown ? `${styles.active_dropdown}` : ''}`}>
                                    <button aria-label='Open and close dropdown menu' onClick={() => toggleDropdown(card.id)}>{card.title}</button>
                                    <ul>
                                        {card.links.map((link) => (
                                            <li key={link.id} style={{ borderColor: accentColor }} onClick={() => toggleDropdown(null)}><Link to={link.link}>{link.title}</Link></li>
                                        ))}
                                    </ul>
                                </div>
                            ))}

                            {data.data.attributes.links.length > 0 && data.data.attributes.links.map((link) => (
                                <Link key={link.id} to={link.link} onClick={() => toggleDropdown(null)}>{link.title}</Link>
                            ))}

                            {/* eslint-disable-next-line */}
                            <a href='https://www.youtube.com/@solhemmetstockholm' target='_blank' rel="noopener" className={styles.youtube} onClick={() => toggleDropdown(null)}>Solhemmet TV</a>
                            {/* <Link to={'/kontakt'} onClick={() => toggleDropdown(null)}>Kontakt</Link> */}
                            {/* eslint-disable-next-line */}
                            <a href="https://modernelder.se/" target="_blank" rel="noopener" className={styles.modern_elder} aria-label='Opens modernelder.se in a new tab'>Modern Elder</a>
                        </nav>
                    }

                    <button aria-label='Open navigation menu' className={styles.btn_header} onClick={toggleNav}>
                        <img src="/icons/menu-icon.svg" alt="Open navigation menu" />
                    </button>
                </div>
            </header>




            {showNav &&
                <div className={styles.nav_menu}>
                    <div className={styles.nav_menu_constraints}>

                        <div className={styles.upper}>
                            <Link className={styles.upper_logo} onClick={toggleNav} to="/" aria-label='Links to front page'>
                                <img src="/images/logo/current/Solhemmet_logo.svg" alt="Solhemmet logo" />
                            </Link>

                            <button aria-label='Close navigation menu' className={`${styles.btn_header} ${styles.close_nav}`} onClick={toggleNav}>
                                <img src="/icons/menu-close-icon.svg" alt="Close navigation menu" />
                            </button>
                        </div>

                        {data &&
                            <nav>
                                {data.data.attributes.linkGroups.length > 0 && data.data.attributes.linkGroups.map((card) => (
                                    <div className={styles.link_collection} key={card.id}>
                                        <h6>{card.title}</h6>
                                        {card.links.map((link) => (
                                            <Link key={link.id} onClick={toggleNav} to={link.link} >{link.title}</Link>
                                        ))}
                                    </div>
                                ))}

                                <div className={styles.link_collection}>
                                    {data.data.attributes.links.length > 0 && data.data.attributes.links.map((link) => (
                                        <Link key={link.id} onClick={toggleNav} to={link.link}>{link.title}</Link>
                                    ))}
                                </div>

                                <div className={styles.link_collection}>
                                    {/* eslint-disable-next-line */}
                                    <a href='https://www.youtube.com/@solhemmetstockholm' target='_blank' rel="noopener" className={styles.youtube} onClick={() => toggleDropdown(null)}>Solhemmet TV</a>
                                    {/* <Link to={'/kontakt'}>Kontakt</Link> */}
                                    {/* eslint-disable-next-line */}
                                    <a href="https://modernelder.se/" target="_blank" rel="noopener" className={styles.modern_elder} aria-label='Opens modernelder.se in a new tab'>Modern Elder</a>
                                </div>
                            </nav>
                        }
                    </div>
                </div>
            }
        </>
    )
}