import styles from './loading-dots.module.scss';

export default function LoadingDots() {
    return (
        <div className={styles.loading_dots_container}>
            <div />
            <div />
            <div />
        </div>
    )
}