import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from './text-section.module.scss';

export default function TextSection({ cards }) {

    return (
        <>
            {Array.isArray(cards) ? (
                <div className={styles.section_container} >

                    <div className={styles.text_container}>
                        {cards.map((card) => (
                            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={card.text} key={card.id} />
                        ))}
                    </div>

                </div>
            ) : (
                <div className={styles.section_container}>

                    <div className={styles.text_container}>
                        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={cards} />
                    </div>

                </div>
            )}
        </>
    )
}