import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useGetEntryByContentTypeAndSlug } from '../../utils/js/getStrapiContent';
import HeadTags from '../../components/HeadTags/HeadTags';
import LoadingDots from '../../components/LoadingDots/LoadingDots';
import Error404 from '../Error404/Error404';
import NewsletterOpening from '../../components/NewsletterOpening/NewsletterOpening';
import NewsletterCardVertical from '../../components/NewsletterCardVertical/NewsletterCardVertical';
import ImageAndText from '../../components/ImageAndText/ImageAndText';
import TextSection from '../../components/TextSection/TextSection';
import NewsletterByline from '../../components/NewsletterByline/NewsletterByline';
import styles from './newsletter-template.module.scss';

export default function NewsletterTemplate({ setCurrentTheme }) {
    const { identifier } = useParams();
    const { data, isPending, error } = useGetEntryByContentTypeAndSlug('solhemmet-newsletter', identifier)


    useEffect(() => {
        setCurrentTheme({
            propertyType: 'default',
            color: '#EEA550'
        })
    }, [setCurrentTheme])


    return (
        <div className={styles.container}>
            {isPending && <LoadingDots />}
            {error && <Error404 />}
            {data && (
                <>
                    {data.data.attributes.seo && <HeadTags seo={data.data.attributes.seo} />}
                    {data.data.attributes.opening && <NewsletterOpening cards={[data.data.attributes.opening]} />}
                    {data.data.attributes.vertical.length > 0 && <NewsletterCardVertical cards={data.data.attributes.vertical} />}
                    {data.data.attributes.horizontal.length > 0 && <ImageAndText cards={data.data.attributes.horizontal} />}
                    {data.data.attributes.text && <TextSection cards={data.data.attributes.text} />}
                    {data.data.attributes.byLineImage &&
                        data.data.attributes.byLineSignature &&
                        data.data.attributes.byLineText &&
                        <NewsletterByline
                            image={data.data.attributes.byLineImage}
                            signature={data.data.attributes.byLineSignature}
                            text={data.data.attributes.byLineText}
                        />
                    }
                </>
            )}
        </div>
    )
}