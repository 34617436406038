import { useEffect, useCallback, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import { DotButton, PrevButton, NextButton } from '../CarouselIndicators/CarouselIndicators';
import HeroSlide from '../HeroSlide/HeroSlide';
import styles from './hero-carousel.module.scss';

export default function HeroCarousel({ slides, hideSun }) {
    const [emblaRef, emblaApi] = useEmblaCarousel({ dragFree: false, loop: true });
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState([]);

    // For arrow indicators
    const scrollPrev = useCallback(() => {
        if (emblaApi) emblaApi.scrollPrev()
    }, [emblaApi])

    const scrollNext = useCallback(() => {
        if (emblaApi) emblaApi.scrollNext()
    }, [emblaApi])

    const scrollTo = useCallback((index) => emblaApi && emblaApi.scrollTo(index), [
        emblaApi
    ]);

    const onSelect = useCallback(() => {
        if (!emblaApi) return;
        setSelectedIndex(emblaApi.selectedScrollSnap());
    }, [emblaApi, setSelectedIndex]);


    useEffect(() => {
        if (emblaApi) {
            onSelect();
            setScrollSnaps(emblaApi.scrollSnapList());
            emblaApi.on("select", onSelect)
        }
    }, [emblaApi, setScrollSnaps, onSelect])


    return (
        <div className={styles.carousel}>
            {slides &&
                <>
                    <div className={styles.embla}>

                        {slides.length <= 1 ? // If only one slide
                            <div className={styles.embla__container}>
                                <HeroSlide slide={slides[0]} hideSun={hideSun} />
                            </div>

                            : // If several slides

                            <div className={styles.embla__viewport} ref={emblaRef}>
                                <div className={styles.embla__container}>
                                    {slides.map((slide, index) => (
                                        <HeroSlide slide={slide} hideSun={hideSun} key={index} />
                                    ))}
                                </div>

                                <PrevButton onClick={scrollPrev} />
                                <NextButton onClick={scrollNext} />

                                <div className={styles.embla__dots}>
                                    {scrollSnaps.map((_, index) => (
                                        <DotButton
                                            key={index}
                                            selected={index === selectedIndex}
                                            onClick={() => scrollTo(index)}
                                            color='white'
                                        />
                                    ))}
                                </div>
                            </div>
                        }

                    </div>
                </>
            }
        </div>
    )
}