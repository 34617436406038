import { Link } from 'react-router-dom';
import formatStrapiImage from '../../utils/js/formatStrapiImage';
import styles from './newsletter-list-item.module.scss';

export default function NewsletterListItem({ cards }) {
    return (<>
        {cards.map((card) => (
            <Link to={`/nb/${card.attributes.slug}`} className={`${styles.container} hover-img-zoom`} key={card.id}>

                <div className={styles.img_container}>
                    <img src={formatStrapiImage(card.attributes.opening.image)} alt={card.attributes.opening.image.data.attributes.alternativeText} />
                </div>

                <div className={styles.text_container}>
                    <h2>{card.attributes.title}</h2>
                </div>

            </Link>
        ))}
    </>)
}