import { useState, useEffect } from 'react';
import ContentBlockedBanner from '../ContentBlockedBanner/ContentBlockedBanner';
import styles from './youtube-embed-section.module.scss';


// Currently only used in RenfallenSurvey. Might have to change if used other places later
export default function YoutubeEmbedSection({ cards }) {
    const [showVideo, setShowVideo] = useState();

    useEffect(() => {
        const consent = localStorage.getItem('third-party-cookie-consent')
        if (consent) {
            setShowVideo(JSON.parse(consent))
        } else {
            setShowVideo(false)
        }
    }, [])

    return (
        <div className={styles.section_container}>

            {Array.isArray(cards) ? (

                cards.map((card) => (
                    showVideo ?
                        <div className={styles.video_container} key={card.Id}>
                            <iframe
                                className={styles.video}
                                title="Video"
                                src={`https://www.youtube-nocookie.com/embed/${card.videoId}?modestbranding=1&color=white&rel=0`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                        </div>
                        :
                        <ContentBlockedBanner url={card.videoId} key={card.Id} />
                ))

            ) : (
                showVideo ?
                    <div className={styles.video_container}>
                        <iframe
                            className={styles.video}
                            title="Video"
                            src={`https://www.youtube-nocookie.com/embed/${cards.videoId}?modestbranding=1&color=white&rel=0`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                        </iframe>
                    </div>
                    :
                    <ContentBlockedBanner url={cards.videoId} />
            )}

        </div>
    )
}