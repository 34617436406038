import { useState, useEffect } from 'react';
import { strapiBaseUrl } from './setStrapiBaseUrl';


export function useGetEntryById(id) {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(`${strapiBaseUrl}${id}?populate=deep`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    setError(data.error)
                } else {
                    setData(data);
                }
                setIsPending(false);
            })
            .catch(err => {
                console.log(err)
                setError(err.message);
                setIsPending(false);
            })
    }, [id])

    return { data, isPending, error };
}


export function useGetEntryByContentTypeAndSlug(contentType, slug) {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(`${strapiBaseUrl}${contentType}/find-by-slug/${slug}?populate=deep`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    setError(data.error)
                } else {
                    // check if not published
                    if (data.data.attributes.publishedAt == null) {
                        throw new Error("Not Found");
                    } else {
                        setData(data);
                    }
                }
                setIsPending(false);
            })
            .catch(err => {
                console.log(err)
                setError(err.message);
                setIsPending(false);
            })
    }, [contentType, slug])

    return { data, isPending, error };
}


export function useGetEntriesByContentType(contentType) {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(`${strapiBaseUrl}${contentType}?populate=deep&publicationState=live&pagination[limit]=100`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    setError(data.error)
                } else {
                    setData(data);
                }
                setIsPending(false);
            })
            .catch(err => {
                console.log(err)
                setError(err.message);
                setIsPending(false);
            })
    }, [contentType])

    return { data, isPending, error };
}
