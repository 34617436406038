import styles from './carousel-indicators.module.scss';

export const DotButton = ({ selected, onClick, color }) => (
    <button
        aria-label='Slide selector button'
        className={`${styles.embla__dot} 
        ${color === 'white' ? `${styles.white}` : `${styles.black}`}
        ${selected ?
                color === 'white' ?
                    `${styles.selected_white}` :
                    `${styles.selected_black}`
                : ""}`
        }
        type="button"
        onClick={onClick}
    />
);

export const PrevButton = ({ onClick }) => (
    <button
        aria-label='Previous slide'
        className={`${styles.embla__btn} ${styles.embla__prev}`}
        onClick={onClick}
    >
        <img src="/icons/chevron.svg" alt="Previous slide chevron" />
    </button>
);

export const NextButton = ({ onClick }) => (
    <button
        aria-label='Next slide'
        className={`${styles.embla__btn} ${styles.embla__next}`}
        onClick={onClick}
    >
        <img src="/icons/chevron.svg" alt="Next slide chevron" />
    </button>
);
