import { useState } from 'react';
import formatStrapiImage from '../../utils/js/formatStrapiImage';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import GallerySelectedItem from '../GallerySelectedItem/GallerySelectedItem';
import styles from './gallery-cards-wide.module.scss';

export default function GalleryCardsWide({ cards }) {
    const [selectedItem, setSelectedItem] = useState();
    const [showItem, setShowItem] = useState(false);

    function toggleItem(card) {
        if (card) {
            setSelectedItem({
                'url': card.image.data.attributes.url,
                'alt': card.image.data.attributes.alternativeText
            });
        }

        setShowItem(!showItem);

        if (showItem) {
            document.body.style.overflow = 'unset';
        } else {
            document.body.style.overflow = 'hidden';
        }
    }

    return (
        <>
            <div className={styles.section_container}>
                {cards.map((card) =>
                    <div className={styles.card_container} key={card.id}>

                        <div className={styles.content}>

                            <div className={`hover-img-zoom ${styles.img_container}`} onClick={() => toggleItem(card)}>
                                <img src={formatStrapiImage(card.image)} alt={card.image.data.attributes.alternativeText} />
                            </div>

                            <div className={styles.text_container}>
                                <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} children={card.text} />
                            </div>

                        </div>

                    </div>
                )}
            </div>

            {showItem && <GallerySelectedItem item={selectedItem} toggleItem={toggleItem} />}
        </>
    )
}